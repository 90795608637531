
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-aside{
	--el-aside-width: auto;

	display: flex;
	// align-items: stretch;
	gap: 16px;
	padding: 16px;
	padding-left: 0;
	overflow: hidden;

	&__main-menu{
		// flex-shrink: 0;
	}
}