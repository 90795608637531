
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.grid-designs-info{
	$this: &;

	width: 100%;
	min-width: fit-content;

	display: flex;
	flex-direction: column;
	gap: 12px;

	text-align: left;
	line-height: 22px;

	&__block{
		display: flex;
		flex-direction: column;

		&.gap-8{
			gap: 8px;
		}
		&.gap-4{
			gap: 4px;
		}
	}
	&__group{
		display: flex;
		flex-direction: column;

		&.pb-8{
			padding-bottom: 8px;
		}

		&.group-row{
			flex-direction: row;
			align-items: center;

			&.gap-48{
				gap: 48px;
			}
			&.gap-32{
				gap: 32px;
			}
			&.gap-16{
				gap: 16px;
			}
			&.gap-8{
				gap: 8px;
			}
			&.row-space-between{
				justify-content: space-between;
			}
			&.align-start{
				align-items: flex-start;
			}
		}
	}
	&__group-label{
		font-size: 10px;
		line-height: 16px;
		color: $light-text-secondary;
	}
	&__group-value{
		@include text-overflow-dots;

		max-width: 90px;
		font-size: 12px;
	}

	&__head{
		cursor: pointer;

		display: flex;
		align-items: center;
		gap: 4px;

		// line-height: 22px;

		transition: 200ms;
		&:hover{
			opacity: 0.8;
			text-decoration: underline;
		}

		.head-add-tprog-icon{
			color: $primary;
			margin-right: 4px;

			.ico{
				@include size(12px)
			}
		}

		.head-gid{
			color: $primary;
			font-weight: 700;
			text-transform: uppercase;
		}

		.head-inner-id {
			color: $light-text-secondary-2;
			font-size: 12px;
		}

		#{$this}--is-compact &{
			position: relative;

			.head-inner-id{
				position: absolute;
				top: 0;
				left: 0;
				transform: translateY(-65%);
				min-width: max-content;
			}

			.head-gid{
				font-size: 12px;
			}
		}
	}
	&__side-select{
		.side-select{
			gap: 4px;

			&__item{
				@include flex-ctr;

				--el-button-size: 18px;

				padding: 2px 8px;
				font-size: 10px;
				line-height: 1;

				.ico{
					height: 11px;
					width: 7px;
				}

				span{
					gap: 2px;
				}

				&.--is-light{
					padding: 2px 4px;
				}
			}

			&__digital-badge{
				@include size(12px);
				top: -5px;
				left: 94%;

				.ico{
					@include size(12px);
				}
			}
		}
	}
	&__btn-actions{
		display: flex;
		align-items: center;
		gap: 8px;

		.btn-action{
			@include size(28px, 23px);

			.ico{
				@include size(13px);
			}
		}
	}

	&__btn-open{
		@include size(24px);
		@include flex-ctr;
		min-width: 24px;
		min-height: 24px;

		cursor: pointer;

		color: $primary;
		background-color: $white;
		box-shadow: $shadow-light-2;

		border-radius: 50%;

		.ico{
			@include size(14px);
			transition: 200ms;
		}

		transition: 200ms;
		&:hover{
			box-shadow: $shadow-light;
		}

		#{$this}--open &{
			.ico {
				transform: rotate(180deg);
			}
		}
	}

	&__btn-actions-item{
		@include size(13px);
		cursor: pointer;
		color: $primary;

		.ico{
			@include size(13px);
		}

		transition: 100ms;

		&:hover{
			transform: scale(1.2);
			background-color: rgba($color: $primary, $alpha: 0.05);
			color: rgba($color: $primary, $alpha: 0.8);
		}
	}

	&__address{
		@include text-overflow-dots;

		max-width: 400px;
		color: $primary;

		// &:hover{
		// 	opacity: 0.8;
		// }
	}

	&__service-status{
		color: $danger;
	}

	&__type{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 4px;

		#{$this}__group{
			gap: 8px;
		}

		#{$this}--is-compact &{
			font-size: 12px;
		}
	}
	&__type-construct{
		color: $light-text-regular;
	}
	&__type-size{
		// margin-left: 8px;
		color: $light-text-regular;
	}
	&__type-mech{
		@include flex-ctr;
		height: 17px;
		width: fit-content;
		padding: 0 5px;
		font-size: 10px;

		color: $primary;
		border: 1px solid $primary;
		border-radius: 2px;
	}
	&__type-construct-icon{
		color: $primary;
		.ico{
			@include size(16px);
		}
	}

	.a{
		text-decoration: underline;
		color: $primary;

		&:hover{
			opacity: 0.8;
		}
	}
}