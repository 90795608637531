
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-breadcrumb{
	&__item{
		--el-text-color-regular: #{$light-text-regular};

		a{
			--el-text-color-primary: #{$light-text-secondary};
			--el-color-primary: #{$primary};

			font-weight: 400;
		}

		.el-icon{
			margin: 0 4px;
		}

		.ico{
			@include size(16px);
			display: block;
			fill: $light-text-secondary;
		}
	}
}