
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.table-grid-td{
	position: relative;
	min-height: 40px;

	padding: var(--td-padding);

	&.td-col-check{
		position: sticky;
		left: 0;
		z-index: 80;

		background-color: white;

		padding: 16px 12px;

		.el-checkbox{
			height: fit-content;

			&__label{
				display: none;
			}
			&__inner{
				outline: 0 !important;
			}
		}
	}

	&.td-fixed-left,
	&.td-fixed-right{
		position: sticky;
		z-index: 80;

		background-color: white;

		&::before{
			content: "";
			position: absolute;
			top: 0;

			display: block;
			height: 100%;
			width: 1px;
		}
	}

	&.td-fixed-left{
		&::before{
			right: -1px;
			border-right: 1px solid $light-border-3;
		}

		&.td-fixed-left-shadow{
			&::before{
				box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, .15);
			}
		}
	}
	&.td-fixed-right{
		&::before{
			left: -1px;
			border-left: 1px solid $light-border-3;
		}
	}
}