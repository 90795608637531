
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.grid-cell-photo{
	@include size(100%);
	// max-width: 70px;

	&__img{
		@include size(100%);
		// object-fit: contain;
		// object-position: center;
	}
}