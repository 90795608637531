
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
@import '@/assets/styles/common/el-dialog-mixin.scss';

.oper-mass-employ-popup{
	@include el-dialog;

	&__content{
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__action-buttons{
		margin-top: 16px;
	}
}