
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-header-auth{
	--el-header-height: 48px;
	--el-header-padding: 0 16px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 32px;

	border-bottom: 1px solid $light-border-2;
	background-color: $white;
	box-shadow: $shadow-light;

	&__left{
		display: flex;
		align-items: center;
		gap: 32px;
	}
}