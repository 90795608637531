
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-single-select{
	$this: &;

	.el-select{
		&__wrapper{
			min-height: 40px;
		}
	}
	.el-tag{
		height: 32px;
	}

	&__opt{
		position: relative;
		padding-left: 16px;

		&.is-selected{
			--el-color-primary: #{$primary};
		}
		&.is-hovering{
			--el-fill-color-light: #{$light-fill-color};

			#{$this}__opt-btn-remove{
				opacity: 0.5;

				&:hover{
					opacity: 1;
				}
			}
		}

		&.--show-checkbox{
			.el-checkbox__inner{
				transition: all 100ms;
			}

			&.is-hovering{
				.el-checkbox__inner{
					border-color: $primary;
				}
			}
			&.is-selected{
				&::after{
					content: none !important;
				}
			}
		}
	}

	&__opt-content{  }
	&__opt-btn-remove{
		@include size(20px);
		@include flex-ctr;

		position: absolute;
		top: 50%;
		right: 16px;
		transform: translate(50%, -50%);
		opacity: 0;

		transition: opacity 100ms;

		.ico{
			@include size(20px);
		}
	}
}