
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
@import '@/assets/styles/common/el-dialog-mixin';

.notify-popup{
	@include el-dialog;
	color: $light-text-regular;
	padding: 0;

	&__header {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;

		.ico {
			@include size(18px);
		}

		.check-green {
			color: $marker-free;
		}
		.check-red {
			color: $marker-busy;
		}

		p {
			font-size: 18px;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__select {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
}