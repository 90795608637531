
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.employ-calendar{
	padding: 4px 0;

	&__head-title{
		padding-bottom: 10px;
		font-size: 12px;
		line-height: 22px;
		color: $light-text-secondary;
	}

	&__wrap{
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
	&__week{
		display: flex;
		gap: 4px;
	}
	&__day{
		@include size(16px);
		@include flex-ctr;
		cursor: default;

		font-size: 10px;
		line-height: 1;
		color: $white;

		background-color: $marker-free;

		&.type-disable{
			background-color: $light-border-2;
		}
		&.type-reserved{
			background-color: $marker-reserved;
		}
		&.type-busy{
			background-color: $marker-busy;
		}
	}
}