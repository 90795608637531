
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
@import '@/assets/styles/common/el-dialog-mixin.scss';

.oper-error-popup{
	@include el-dialog;

	min-width: 500px;
	width: auto;

	&__content{
		color: $light-text-regular;
	}
}