
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.grid-pagination{
	display: flex;
	align-items: center;
	gap: 16px;

	&__count-page{
		display: flex;
		align-items: center;
		gap: 16px;
	}
	&__count-page-select{
		width: 68px;

		.el-select{
			&__wrapper{
				min-height: 26px;
			}
			&__selected-item{
				color: $primary;
			}
		}
	}
	&__paginator{
		.el-pager{
			--el-pagination-button-color: #{$light-text-secondary-2};
		}
		.el-pagination{
			--el-pagination-button-color: #{$light-text-secondary-2};
			--el-fill-color-blank: transparent;
		}
	}
}