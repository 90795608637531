
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-header{
	--el-header-height: 48px;
	--el-header-padding: 0 16px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 32px;

	border-bottom: 1px solid $light-border-2;
	background-color: $white;
	box-shadow: $shadow-light;

	&__left{
		display: flex;
		align-items: center;
		gap: 32px;
	}
	&__breadcrumb{
		max-width: 450px;

		.el-breadcrumb{
			line-height: 1.2;

			&__inner{
				max-width: 350px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&__head-title{
		max-width: 600px;
		text-align: center;
		line-height: 1;
		color: $primary;
	}
	&__right{
		display: flex;
		align-items: center;
	}
	&__notif{
		padding: 0 4px;
	}
	&__user-info{
		padding: 0 4px;
	}

	&__dropdown-menu-item{
		gap: 12px;

		.ico{
			@include size(18px);
		}
	}
}