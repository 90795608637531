
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
@import '@/assets/styles/common/el-dialog-mixin';

.upload-popup{
	@include el-dialog;
	$this: &;

	.el-dialog{
		&__body{
			padding-bottom: 0;
		}
	}

	&__content{
		display: flex;
		align-items: center;
		flex-direction: column;

		padding: 24px 70px;
	}

	&__drag-zone{
		@include flex-ctr;
		position: relative;

		width: 100%;
		height: 100px;

		border-radius: $base-radius;
		border: 1px solid $light-border-3;
		background-color: $primary-light-9;

		&.--is-draggable{
			border-color: $primary-light-8;
			border-style: dashed;

			#{$this}__icon{
				animation: ping 0.8s ease-in-out infinite both;
			}
		}
	}
	&__input{
		@include size(100%);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		opacity: 0;
	}
	&__icon{
		color :#A0CFFF;

		.ico{
			@include size(64px);
		}
	}


	&__info{
		margin-top: 16px;

		.a{
			cursor: pointer;
			color: $primary;
			text-decoration: underline;

			transition: 200ms;
			&:hover{
				opacity: 0.7;
			}
		}
	}

	&__btn-action{
		margin-top: 32px;
	}


	@keyframes ping {
		0% {
			-webkit-transform: scale(0.2);
			transform: scale(0.2);
			opacity: 0.8;
		}

		80% {
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
			opacity: 0;
		}

		100% {
			-webkit-transform: scale(2.2);
			transform: scale(2.2);
			opacity: 0;
		}
	}
}