
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.cell-operator{
	&__label{
		color: $primary;
		text-decoration: underline;

		transition: 200ms;
		&:hover{
			opacity: 0.7;
		}
	}
}