
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.side-select{
	$this: &;

	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	&__item{
		position: relative;

		padding: 5px 12px;
		margin: 0;
		border-radius: 4px;

		font-size: 14px;

		span{
			display: flex;
			align-items: center;
			gap: 4px;
		}

		.ico{
			width: 11px;
			height: 16px;
		}

		&.--is-light{
			padding: 5px 8px;
		}
		&.--is-active{
			border-color: $marker-added;
		}
		&.--is-in-target-program{
			background: linear-gradient(0deg, rgba(82, 41, 161, 0.20) 0%, rgba(82, 41, 161, 0.20) 100%), #FFF;
		}
		&.--is-not-working{
			border-color: $btn-disabled;
			color: $btn-disabled;

			&.--is-active{
				border-color: $light-text-secondary;
			}

			#{$this}__item-label{
				color: $btn-disabled;
				font-weight: 400;
			}

			#{$this}__digital-badge{
				background-color: $btn-disabled;
			}
		}
	}

	&__item-label{
		.--is-active &{
			font-weight: 700;
		}
	}

	&__digital-badge{
		@include size(12px);
		@include flex-ctr;
		// flex-direction: column;

		position: absolute;
		top: -4px;
		left: 100%;
		transform: translateX(-50%);

		// font-size: 4px;
		// line-height: 1;
		// color: $white;

		border: 1px solid $white;
		border-radius: 50%;
		background-color: $primary;

		.svg-icon{
			@include size(60%);
		}
		.ico{
			@include size(100%);
		}
	}

	.el-button+.el-button{
		margin: 0;
	}
}