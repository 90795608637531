
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.tgth-all-check-settings{
	&__popover{
		--el-popover-padding: 8px 20px;
	}
	&__popover-container{
		width: 200px;
	}
}