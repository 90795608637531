
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.nt-cell-user{
	.notif-system-info{
		--color-icon: #{$marker-free};
		--color-label: #{$marker-free};
	}
}