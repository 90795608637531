
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-date-picker{
	// --el-input-height: 40px;

	&__popover{
		--el-color-primary: #{$primary};

		.el-month-table td.disabled.today .cell{
			color: $primary;
		}
		.el-month-table td.current:not(.disabled) .cell{
			font-weight: 500;
			color: $white;
			background-color: $primary;
		}
	}
}