
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.footer-auth{
	@include flex-ctr;
	padding: 16px;

	line-height: normal;

	&__wrap{
		display: flex;
		align-items: center;

		gap: 24px;
	}
	&__link{
		display: flex;
		align-items: center;
		gap: 4px;

		font-size: 14px;
		color: $primary;

		transition: 200ms;
		&:hover{
			opacity: 0.8;
		}
	}
	&__link-icon{
		width: fit-content;
		height: fit-content;

		.ico{
			@include size(18px);
		}
	}
	&__link-label{
		text-decoration: underline;
	}

	&__copy{
		font-size: 13px;
		text-transform: uppercase;
		color: $light-text-placeholder;
	}

}