
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.notif-feed{
	&__popover{
		--el-popover-padding: 16px;
	}
	&__container{
		@include size(100%);

		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__btn{
		.el-badge{
			--el-badge-size: 16px;
			--el-badge-padding: 4px;
			// --el-badge-bg-color: #{$danger};
			--el-badge-font-size: 10px;

			&__content{
				min-width: 16px;
				// font-size: 10px;
				font-weight: 700;
				background-color: $danger;

				&.is-fixed{
					right: 10px;
					top: 5px;
				}
			}
		}
	}

	&__head{
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 32px;

		padding: 0 8px;
		// padding-left: 8px;
		// padding-right: 24px;
	}
	&__center-link{
		cursor: pointer;

		font-size: 12px;
		text-decoration: underline;
		color: $primary;

		transition: 200ms;
		&:hover{
			opacity: 0.8;
		}
	}
	&__all-checked{
		cursor: pointer;
		color: $primary;

		transition: 200ms;
		&:hover{
			opacity: 0.8;
		}

		.ico{
			@include size(16px);
		}
	}

	&__list{
		flex-grow: 1;
	}

	&__wrapper{
		@include scrollbar;
		@include size(100%);

		display: flex;
		flex-direction: column;

		max-height: 70Vh;
		overflow-y: auto;
		gap: 16px;

		padding-right: 16px;
	}

	&__item{
		border-bottom: 1px solid $darken-border;
	}
}