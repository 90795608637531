
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							

.bs-main{
	--el-main-padding: 0;
	overflow: unset;
}
