
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.edit-queue-item{
	display: flex;
	align-items: center;
	gap: 16px;

	padding: 8px 16px;

	&__check{
		@include flex-ctr;

		.el-checkbox{
			height: auto;

			&__label{
				display: none;
			}
		}
	}
	&__drop{
		cursor: move;
		color: $btn-disabled;

		transition: 200ms;
		&:hover{
			color: $light-text-secondary-2;
		}

		.ico{
			@include size(16px);
		}
	}
	&__info{
		display: flex;
		align-items: center;

		flex: 1;
		gap: 8px;
		font-size: 12px;
		// line-height: 1;
		color: $light-text-regular;
	}
	&__position,
	&__number{
		font-size: 14px;
		font-weight: 700;
		text-decoration: underline;
		color: $primary;
	}
	&__contact{
		display: flex;
		align-items: center;
		gap: 8px;
	}
	&__soc{
		@include size(16px);
		cursor: pointer;
		color: $btn-disabled;

		.ico{
			@include size(16px);
		}

		transition: 100ms;
		&:hover{
			transform: scale(1.2);
			background-color: rgba($color: $primary, $alpha: 0.05);
			color: rgba($color: $primary, $alpha: 0.8);
		}
	}
	&__user-card{
		cursor: pointer;
		color: $primary;

		transition: 200ms;
		&:hover{
			transform: scale(1.1);
			background-color: rgba($color: $primary, $alpha: 0.05);
			color: rgba($color: $primary, $alpha: 0.8);
		}

		.ico{
			@include size(16px);
		}
	}
	&__remove{
		cursor: pointer;
		color: $danger;

		transition: 200ms;
		&:hover{
			// color: $primary;
			color: rgba($color: $danger, $alpha: 0.7);
			transform: scale(1.05);
		}
		&:active{
			transform: scale(0.98);
		}

		.ico{
			@include size(16px);
		}
	}
}