
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.notif-system-info{
	--color-icon: #{$primary};
	--color-label: #{$primary};

	display: flex;
	align-items: center;
	gap: 8px;

	&__icon{
		@include flex-ctr;
		@include size(32px);

		border-radius: 50%;
		background-color: var(--color-icon);

		.ico{
			@include size(20px);
			color: $white;
		}
	}
	&__label{
		color: var(--color-label);
	}
}