
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.bs-btn{
	--el-button-border-color: #{$light-border};
	--el-button-hover-text-color: #{$light-text-regular};
	--el-button-hover-bg-color: #F2F2F2;
	--el-button-hover-border-color: #{$light-border};

	--el-button-active-text-color: #{$light-text-regular};
	--el-button-active-bg-color: #{$white};
	--el-button-active-border-color: #{$light-text-placeholder};

	--el-border-radius-base: 2px;
	--el-button-size: 40px;

	height: var(--el-button-size);
	padding: 8px 20px;

	&.el-button--small{
		--el-button-size: 32px;
	}

	&.el-button--primary{
		--el-button-bg-color: #{$primary};
		--el-button-border-color: #{$primary};
		--el-button-hover-text-color: #{$white};
		--el-button-hover-bg-color: #633EAA;
		--el-button-hover-border-color: #633EAA;

		--el-button-active-text-color: #{$white};
		--el-button-active-bg-color: #422181;
		--el-button-active-border-color: #422181;
	}

	&.is-disabled,
	&:disabled{
		--el-button-disabled-text-color: #{$btn-disabled};
		--el-button-disabled-bg-color: #{$white};
		--el-button-disabled-border-color: #{$btn-disabled};
	}
}