
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.nt-cell-message{
	&__bg{
		@include size(100%);

		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

		background-color: rgba($color: $primary, $alpha: 0.05);
	}

	&__text{
		position: relative;
		z-index: 1;
		text-align: left;
	}
}