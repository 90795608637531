
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.mass-static-table{
	$this: &;
	// @include size(100%);
	position: relative;

	&__container{
		// @include size(100%);
		// position: absolute;
		// top: 0;
		// left: 0;
		// z-index: 1;

		--el-fill-color-lighter: #{$light-fill-color-2};
		// --el-table-header-bg-color: #{$light-fill-color-2};
		// --el-table-row-hover-bg-color: #{darken($color: $light-fill-color-2, $amount: 1%)};
		// --el-table-row-hover-bg-color: #{ rgba($color: $primary-light, $alpha: 0.05) };
		--el-table-row-hover-bg-color: #{$light-border-3};
		--el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0,0,0,.15);

		.el-table{

			&__cell{
				padding: 8px 0;

				.cell{
					padding: 0 8px;
				}

				&#{$this}__check{
					.cell{
						padding-right: 0;
					}
				}
			}
		}

		td.el-table__cell{
			border-bottom: none;
		}
	}

	&__cell{
		@include flex-ctr;

		margin: 0 auto;
		color: $light-text-regular;

		&.cell-gid{
			width: max-content;
			font-weight: 700;
			color: $primary;
		}
		&.cell-gid-partner{
			width: max-content;
			color: $light-text-secondary-2;
		}
		&.cell-type-construct{
			color: $primary;

			.ico{
				@include size(18px);
			}
		}
		&.cell-remove{
			justify-content: end;
		}
	}

	&__label{
		width: max-content;
		padding: 0 6px;
		font-size: 9px;
		line-height: 16px;

		color: $primary;
		border-radius: 2px;
		border: 1px solid $primary;
	}

	&__btn-remove{
		@include size(16px);
		cursor: pointer;
		color: $light-text-secondary;

		transition: 200ms;
		&:hover{
			color:  $light-text-secondary-2;
		}

		.ico{
			@include size(16px);
		}
	}
}