
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
// @import "utils/libs";
// @import "utils/vars";
// @import "utils/mixins";
// @import "utils/fonts";
@import "utils/reset";

@import './common/containers';
@import './common/headlines';
@import './common/form-control';
@import './common/feedback/bs-message-box';
@import './common/el-select';

:root {
	--el-color-primary: #{$primary};
	--el-font-family: #{$mainFont};
}

body {
	@include scrollbar($size: 0px);

	font-family: $mainFont;
	font-size: $mainFontSize;
	font-weight: $mainFontWeight;
	line-height: $mainLineHeight;
	color: $mainFontColor;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-optical-sizing: auto;

	background-color: $body-background;
}

input {
	font-family: $mainFont;

	&::placeholder{
		font-family: $mainFont;
	}

	&[type="number"]::-webkit-inner-spin-button,
	&[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}
}
