
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.side-queue-item{
	display: flex;
	align-items: center;
	gap: 8px;

	font-size: 12px;

	&__date{
		color: $black;
	}
	&__index,
	&__target-id{
		font-size: 14px;
		font-weight: 700;
		color: $primary;
	}
	&__status{
		display: flex;
		flex-direction: row;
		gap: 8px;
		height: fit-content;
		.el-tag{
			min-width: 100px;
			height: 20px;
			padding: 0 8px;
			background-color: $white;
			border: 1px solid;
		}
		.primary{
			color: $primary
		}
		.danger{
			color: $marker-busy
		}
		.warning {
			color: $marker-reserved;
		}
		.success {
			color: $marker-free;
		}
	}
	&__target-name,
	&__user-name{
		color: $black;
	}

	&__telegram,
	&__email,
	&__phone{
		@include size(16px);
		cursor: pointer;
		color: $btn-disabled;
		margin-bottom: 2px;

		.ico{
			@include size(16px);
		}

		transition: 100ms;

		&:hover{
			transform: scale(1.2);
			background-color: rgba($color: $primary, $alpha: 0.05);
			color: rgba($color: $primary, $alpha: 0.8);
		}
	}
}