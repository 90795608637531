
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.all-operator-table{
	@include size(100%);
	position: relative;

	&__container{
		@include size(100%);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		--el-fill-color-lighter: #{$light-fill-color-2};
		--el-table-header-bg-color: #{$light-fill-color-2};
		// --el-table-row-hover-bg-color: #{darken($color: $light-fill-color-2, $amount: 1%)};
		// --el-table-row-hover-bg-color: #{ rgba($color: $primary-light, $alpha: 0.05) };
		--el-table-row-hover-bg-color: #{$light-border-3};
		--el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0,0,0,.15);

		.el-table{
			&__cell{
				padding: 13px 0;
			}
		}
	}

	&__head-cell{
		display: flex;
		align-items: center;
		justify-content: space-between;
		// text-align: left;

		gap: 8px;

		.icon-bell,
		.icon-more-dots{
			// cursor: pointer;
			// color: $light-text-regular;

			@include size(16px);
			.ico{
				@include size(16px);
			}
		}
	}

	&__cell-padx-16{
		.cell{
			padding: 0 16px;
		}
	}

	&__cell{
		color: $light-text-regular;
	}
}