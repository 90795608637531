
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.table-grid-th{
	position: relative;

	padding: var(--th-padding);
	color: var(--th-cell-font-color);
	font-weight: 500;
	font-size: 14px;

	&::after{
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;

		display: block;
		width: 100%;
		height: 1px;

		border-bottom: 1px solid $light-border-3;
	}

	&.th-col-green{
		background-color: #E1F3D8;
	}

	&.th-col-check{
		position: sticky;
		left: 0;
		z-index: 90;

		padding: 16px 12px;
		background-color: var(--th-bg-color);

		.el-checkbox{
			height: fit-content;
			&__label{
				display: none;
			}
			&__inner{
				outline: 0 !important;
			}
		}
	}
	&.th-fixed-left,
	&.th-fixed-right{
		position: sticky;
		z-index: 90;

		background-color: var(--th-bg-color);

		&::before{
			content: "";
			position: absolute;
			top: 0;

			display: block;
			height: 100%;
			width: 1px;
		}
	}
	&.th-fixed-left{
		&::before{
			right: -1px;
			border-right: 1px solid $light-border-3;
		}

		&.th-fixed-left-shadow{
			&::before{
				box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, .15);
			}
		}
	}
	&.th-fixed-right{
		&::before{
			left: -1px;
			border-left: 1px solid $light-border-3;
		}
	}
}