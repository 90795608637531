
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.table-grid-ft{
	.table-grid-td{
		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;

			height: 1px;
			width: 100%;
			background-color: $white;
		}
		// box-shadow: -5px 5px 0px 3px black;
	}

	.td-fixed-right,
	.td-fixed-left{
		background-color: var(--ft-bg-color);
	}
}