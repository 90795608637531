
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.table-com-panel {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 64px;

	height: 36px;
	// width: 390px;

	padding: 0 8px;

	background-color: $white;
	border-radius: $base-radius;
	box-shadow: $shadow-light-2;

	&.--padding-right{
		padding-right: 16px;
	}

	&__label{}
	&__wrap{
		display: flex;
		align-items: center;
		gap: 16px;
	}
	&__item{
		cursor: pointer;

		font-size: 12px;
		color: $primary;

		&:hover{
			text-decoration: underline;
			// font-weight: 500;
		}

		&.item-other{
			@include size(16px);

			.ico{
				@include size(16px);
				display: block;
				transform: rotate(90deg);
			}

			transition: 200ms;
			&:hover{
				transform: scale(1.1);
				background-color: rgba($color: $primary, $alpha: 0.05);
				color: rgba($color: $primary, $alpha: 0.8);
			}
		}
	}
	&__popover{
		&.el-popover{
			padding: 8px;
			min-width: 120px;
		}
	}
	&__other-wrap{
		// width: fit-content;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	&__other-item{
		cursor: pointer;

		font-size: 12px;
		line-height: 22px;
		color: $primary;

		&:hover{
			text-decoration: underline;
			// font-weight: 500;
		}
	}
}