
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							


.bs-avatar{
	--el-avatar-text-size: 16px;
	--el-avatar-bg-color: #{$primary-light};
}