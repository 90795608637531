
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.surface-month-item{
	@include size(100%);
	@include flex-ctr;
	position: relative;

	border: 1px solid $white;
	background-color: $marker-free;

	&__label{
		color: $white;
		font-size: 12px;

		.status-reserved &{
			color: $light-text-regular;
		}

		.type-multi &{
			@include size(20px);
			@include flex-ctr;
			position: absolute;
			top: 50%;
			left: 18px;
			z-index: 10;
			transform: translateY(-50%);

			padding-bottom: 2px;

			color: $light-text-regular;
			background-color: $white;
		}
	}

	&.status-reserved{
		background-color: $marker-reserved-2;
	}
	&.status-busy{
		background-color: $marker-busy;
	}
	&.status-dismounted{
		background-color: $marker-dismounted;
	}
	&.status-fract{
		border-width: 3px;
		border-color: $primary;
	}
	// &.status-is-overbook{
	// 	border-width: 3px;
	// 	border-color: $danger;
	// }
	&.type-added{
		position: relative;
		// border-color: $marker-added;

		// &::before{
		// 	@include size(100%);

		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	display: block;

		// 	background-color: rgba($color: $black, $alpha: 0.2);
		// }

		&::after{
			@include size(11px, 6px);

			content: '';
			position: absolute;
			top: 3px;
			right: 3px;
			// left: 72%;
			z-index: 2;
			display: block;
			transform: rotate(-45deg);

			border-left: 1px solid $white;
			border-bottom: 1px solid $white;
		}
	}

	&.type-multi{
		flex-direction: column;
		gap: 2px;

		font-size: 12px;
		line-height: 1;

		background-color: $white;
	}
	&__chunk{
		display: flex;
		align-items: center;
		width: 100%;
		flex-grow: 1;

		padding: 0 4px;
		padding-bottom: 2px;
		color: $white;
		background-color: $marker-free;

		&.chunk-type-reserved{
			color: $light-text-regular;
			background-color: $marker-reserved-2;
		}
	}


}