
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
@import '@/assets/styles/common/views/mixins/default-view-mixin';

.operators-view{
	@include default-view;

	&__action-panel{
		position: relative;
		gap: 16px;
	}

	&__action-item{
		&.item-import-digital{
			.bs-btn{
				height: 36px;

			}
		}
		&.item-import-static{
			.bs-btn{
				height: 36px;
				--el-button-text-color: #{ $white };
				--el-button-bg-color: #{ $marker-free };
				--el-button-border-color: #{ $marker-free };

				--el-button-hover-text-color: #{ $white };
				--el-button-hover-bg-color: #{ color.adjust($color:  $marker-free, $lightness: 10%) };
				--el-button-hover-border-color: #{ color.adjust($color:  $marker-free, $lightness: 10%) };


				--el-button-active-text-color: #{ $white };
				--el-button-active-bg-color: #{ color.adjust($color:  $marker-free, $blackness: 10%) };
				--el-button-active-border-color: #{ color.adjust($color:  $marker-free, $blackness: 10%) };
			}
		}
		&.item-command-panel{
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}