
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.employ-datepicker{
	width: fit-content;
	border: 1px solid $light-border;
	border-radius: $base-radius;
	background-color: $white;

	&__main{
		.air-datepicker{
			--adp-font-family: #{ $mainFont };
			--adp-font-size: 14px;
			--adp-color: #{ $light-text-regular };
			--adp-color-other-month: #{ $btn-disabled };
			// --adp-color-current-date: #{$light-text-regular};
			--adp-cell-background-color-selected: #{ rgba($color: $primary, $alpha: 0.5) };
			--adp-cell-background-color-selected-hover: #{ rgba($color: $primary, $alpha: 0.6) };
			--adp-border-color-inline: transparent;
			--adp-cell-border-radius: 50%;
			--adp-day-cell-height: 27px;
			--adp-width: 220px;

			&--navigation{
				display: none;
			}
			&-body{
				&--cells.-days-{
					align-items: center;
					justify-items: center;
				}
			}
			&-cell{
				// @include size(30px);
				@include size(24px);

				user-select: none;
				font-size: 12px;
				color: $marker-free;

				&.-selected-{
					color: $white;
				}

				&.type-disable{
					color: $light-border-2;

					&.-selected-{
						color: $white;
						background-color: rgba($color: $light-border-2, $alpha: 0.8);
					}
					&.-focus-{
						color: $white;
						background-color: rgba($color: $light-border-2, $alpha: 1);
					}
				}
				&.type-reserved{
					color: $marker-reserved;

					&.-selected-{
						color: $white;
						background-color: rgba($color: $marker-reserved, $alpha: 0.8);
					}
					&.-focus-{
						color: $white;
						background-color: rgba($color: $marker-reserved, $alpha: 1);
					}
				}
				&.type-busy{
					color: $marker-busy;

					&.-selected-{
						color: $white;
						background-color: rgba($color: $marker-busy, $alpha: 0.8);
					}
					&.-focus-{
						color: $white;
						background-color: rgba($color: $marker-busy, $alpha: 1);
					}
				}
			}
		}
	}

	&__footer{
		padding: 8px 12px;
		padding-bottom: 10px;
	}
}