
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.head-btn-sort{
	@include size(16px);
	cursor: pointer;
	padding-top: 1px;

	transition: color 200ms;

	.ico{
		@include size(16px);
		transition: transform 300ms;
	}

	&:hover{
		color: $light-text-regular;
		.ico {
			transform: scale(1.1);
		}
	}

	&--asc{
		color: $primary;
		&:hover{
			color: $primary;

			.ico {
				transform: scale(1.1);
			}
		}
	}

	&--desc{
		color: $primary;
		.ico {
			transform: rotate(180deg);
		}

		&:hover{
			color: $primary;
			.ico {
				transform: rotate(180deg) scale(1.1);
			}
		}
	}
}