
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.operator-detail-table{
	@include size(100%);
	position: relative;

	&__container{
		@include size(100%);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		--el-fill-color-lighter: #{$light-fill-color-2};
		--el-table-header-bg-color: #{$light-fill-color-2};
		// --el-table-row-hover-bg-color: #{darken($color: $light-fill-color-2, $amount: 1%)};
		// --el-table-row-hover-bg-color: #{ rgba($color: $primary-light, $alpha: 0.05) };
		--el-table-row-hover-bg-color: #{$light-border-3};
		--el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0,0,0,.15);

		.el-table{
			$this: '.el-table';

			// &__cell{
			// 	padding: 13px 0;
			// }

			&__cell {
				padding: 16px 0;
				.cell{
					padding: 0 16px;
				}
			}

			&__row.hover-row #{$this}__cell{
				border-right: 1px solid $white;
			}

			&__header .th-month-active{
				background-color: $light-fill-blue;
			}

			&__header #{$this}__cell{
				padding: 13px 0;
				font-weight: 500;

				.cell{
					padding: 0 16px;
				}
			}

			&__header #{$this}_1_column_1{
				padding: 13px 0;
				.cell{
					padding: 0 12px;
				}
			}

			&_1_column_1{
				padding: 16px 0;

				.cell{
					padding: 0 12px;
				}
			}

			// &_1_column_2{
			// 	vertical-align: baseline;
			// }

			&.is-scrolling-middle #{$this}-fixed-column--left{
				border-right: 1px solid var(--el-fill-color-lighter);
			}

			&__footer{
				.cell{
					padding: 0 16px;
					margin-top: -8px;
					margin-bottom: -8px;
				}
			}
		}
	}

	&__th{
		// height: 88px;

		&.el-table{
			&__cell{
				padding: 16px 0;
			}
		}


		&.th-fill-blue{
			background-color: $light-fill-blue !important;
		}
		&.th-fill-violet{
			background-color: $light-fill-violet !important;
		}
		// &.th-fill-red{
		// 	background-color: rgba($color: $marker-busy, $alpha: 0.2) !important;
		// }
		// &.th-fill-green{
		// 	background-color: rgba($color: $marker-free, $alpha: 0.2) !important;
		// }
		// &.th-fill-orange{
		// 	background-color: #F8E3C5 !important;
		// }

		.cell{
			line-height: 1;
			overflow: unset;
		}
	}

	&__td{
		&.td-design-info{
			.cell{
				overflow: unset;
			}
		}

		// &.td-fill-red{
		// 	background-color: rgba($color: #FDE2E2, $alpha: 0.3);
		// }
		// &.td-fill-orange{
		// 	background-color: rgba($color: #F8E3C5, $alpha: 0.15);
		// }
	}

	&__head-cell{
		// color: $light-text-regular;
	}
}