
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
@import '@/assets/styles/common/views/mixins/default-view-mixin';

.notification-center-view{
	@include default-view;

}