
								@use 'sass:color';
								@import '@/assets/styles/utils/vars.scss';
								@import '@/assets/styles/utils/mixins.scss';
							
.grid-employ-info{
	&__digital{
		display: flex;
		flex-direction: column;
		gap: 16px;

		// .employ-info-digital{
		// 	&__month-item{
		// 		height: 14px;
		// 		font-size: 9px;
		// 		font-weight: 500;
		// 		padding-bottom: 1px;
		// 	}
		// 	&__surface-month-list{
		// 		margin-top: 2px;
		// 	}
		// 	&__surface-month-item{
		// 		width: calc( 100% / 12 );
		// 		height: 26px;
		// 		margin: 0;
		// 	}
		// 	&__period{
		// 		height: 8px;
		// 		font-size: 9px;
		// 	}
		// }
		// .surface-month-item{
		// 	gap: 2px;
		// 	font-size: 9px;

		// 	&__label{
		// 		font-size: 9px;
		// 	}
		// 	&__chunk{
		// 		padding-bottom: 0;
		// 	}

		// 	&.status-is-overbook{
		// 		border-width: 2px;
		// 	}
		// }

		// .type-multi .surface-month-item__label{
		// 	@include size(12px);
		// 	left: unset;
		// 	right: 0;
		// }

		.el-input{
			--el-input-height: 32px;
			--el-font-size-base: 12px;

			&__inner{
				&::placeholder{
					font-size: var(--el-font-size-base);
				}
			}
		}

		.el-select{
			&__wrapper{
				min-height: 24px;
				font-size: 12px;
			}
		}

		.el-tag{
			height: 24px;
		}
	}

	&__static{
		// .employ-info-static{
		// 	&__month-list{
		// 		padding-left: 10px;
		// 	}
		// 	&__month-item{
		// 		height: 14px;
		// 		font-size: 9px;
		// 		font-weight: 500;
		// 		padding-bottom: 1px;
		// 	}
		// 	&__surface{
		// 		height: 16px;
		// 		gap: 3px;
		// 	}
		// 	&__surface-index{
		// 		// font-size: 9px;
		// 	}
		// 	&__surface-list{
		// 		margin-top: 2px;
		// 		gap: 6px;
		// 	}
		// 	&__surface-month-item{
		// 		width: calc( 100% / 12 );
		// 		margin: 0;

		// 		&.type-added::after{
		// 			height: 6px;
		// 			width: 10px;
		// 		}
		// 	}
		// 	&__period{
		// 		height: 8px;
		// 		font-size: 9px;
		// 	}
		// }
	}

	&__select-popover{
		.el-checkbox{
			--el-checkbox-font-size: 12px;
		}
	}

	&__group{
		display: flex;
		flex-direction: column;

		&.group-row{
			flex-direction: row;
			align-items: center;
			gap: 10px;
		}
	}

	&__calendar{
		margin-top: 14px;
	}

	.control{
		&__label{
			color: $light-text-regular;
		}
	}
}